/* eslint-disable immutable/no-this */

import { Commons } from '../commons'

export class Login {
   private setFadeInProviders() {
      const providerCollection =
         document.getElementsByClassName('panel-right-border')
      const providersArr = Array.from(providerCollection)

      if (!!providersArr.length)
         providersArr.forEach((provider) => provider.classList.add('fadeIn'))
   }

   private setOnlyDesktop() {
      const mobileCollection = document.getElementsByClassName('visible-xs')
      const mobile = Array.from(mobileCollection)

      const desktopCollection = document.getElementsByClassName('visible-lg')
      const desktop = Array.from(desktopCollection)

      const modalCollection = document.getElementsByClassName('modal-body')
      const modals = Array.from(modalCollection)

      modals.forEach((modal) =>
         modal.setAttribute('style', 'padding: 25px 10px 40px!important'),
      )

      if (!!mobile.length && !!desktop.length) {
         mobile.forEach((item) => item.parentElement!.removeChild(item))

         desktop.forEach((item) =>
            item.classList.remove('visible-md', 'visible-lg'),
         )
      }

      const hasSession = document.getElementById('div-logout')

      if (!!hasSession) {
         const formCollection = document.getElementsByTagName('form')
         const forms = Array.from(formCollection)

         forms[0].parentElement!.setAttribute('style', 'width: 100')
         forms[0].parentElement!.parentElement!.setAttribute(
            'style',
            'padding: 0 20px;width: 100%;',
         )
      }
   }

   private setInputs() {
      const inputCollection = document.getElementsByTagName('input')
      const inputs = Array.from(inputCollection)

      const labelCollection = document.getElementsByTagName('label')
      const labels = Array.from(labelCollection)

      inputs.forEach((item) => {
         item.setAttribute('autocomplete', 'off')
         item.setAttribute('title', 'Please fill out this field.')
         item.classList.remove('inputField-customizable')

         const tagName: string = item.parentElement!.tagName

         if (tagName === 'DIV') {
            labels.forEach((label) =>
               label.classList.remove('label-customizable'),
            )

            const element = labels.find((label) => label.htmlFor === item.id)

            if (!!element) {
               item.parentElement!.classList.add('label-float')
               item.parentElement!.append(element)
            }
         }
      })

      labels.forEach((item) => {
         const tagName: string = item.parentElement!.tagName
         if (tagName === 'FORM') item.parentElement!.removeChild(item)
         if (item.innerHTML === 'Username')
            item.innerHTML.replace('Username', 'Username or e-mail')
      })
   }

   private setButtonIDP() {
      const buttonsCollection = document.getElementsByClassName(
         'idpButton-customizable',
      )

      const IdpButtons = Array.from(buttonsCollection)

      if (!!IdpButtons.length) {
         const formCollection = document.getElementsByClassName('cognito-asf')
         const forms = Array.from(formCollection)

         forms.forEach((form) => {
            form.insertAdjacentHTML(
               'afterend',
               `<div class="line-or"><span data-i18n="or">or</span></div>`,
            )
         })

         IdpButtons.forEach((button) => {
            button.classList.add('btn-oauth')
         })
      }
   }

   public init() {
      const commons = new Commons()

      this.setOnlyDesktop()
      this.setInputs()
      this.setButtonIDP()
      this.setFadeInProviders()

      commons.setReCaptcha()
   }
}
