/* eslint-disable immutable/no-mutation */
/* eslint-disable immutable/no-this */

import { Commons } from '../commons'

export class ConfirmForgotPassword {
   private setInputs() {
      const forgot_password_code = document.getElementById(
         'forgot_password_code',
      )
      forgot_password_code!.setAttribute('autocomplete', 'off')
      forgot_password_code!.setAttribute('placeholder', 'Code')
      forgot_password_code!.classList.remove('inputField-customizable')
      forgot_password_code!.setAttribute('title', 'Please fill out this field.')

      const new_password = document.getElementById('new_password')
      new_password!.setAttribute('autocomplete', 'off')
      new_password!.setAttribute('placeholder', 'New password')
      new_password!.classList.remove('inputField-customizable')
      new_password!.setAttribute('title', 'Please fill out this field.')

      const confirm_password = document.getElementById('confirm_password')
      confirm_password!.setAttribute('autocomplete', 'off')
      confirm_password!.setAttribute('placeholder', 'Confirm new password')
      confirm_password!.classList.remove('inputField-customizable')
      confirm_password!.setAttribute('title', 'Please fill out this field.')

      const formCollection = document.getElementsByTagName('form')
      var forms = Array.from(formCollection)

      const code = document.createElement('label')
      code.innerText = 'Code'

      const newPassword = document.createElement('label')
      newPassword.innerText = 'New password'

      const confirmNewPassword = document.createElement('label')
      confirmNewPassword.innerText = 'Confirm new password'

      const div1 = document.createElement('div')
      div1.classList.add('label-float')
      div1.append(forgot_password_code!)
      div1.append(code)

      const div2 = document.createElement('div')
      div2.classList.add('label-float')
      div2.append(new_password!)
      div2.append(newPassword)

      const div3 = document.createElement('div')
      div3.classList.add('label-float')
      div3.append(confirm_password!)
      div3.append(confirmNewPassword)

      const message = document.getElementById('div-forgot-password-msg')

      forms.forEach((item) => {
         item.prepend(div3)
         item.prepend(div2)
         item.prepend(div1)
         item.prepend(message!)

         const labelCollection = document.getElementsByTagName('label')
         const labels = Array.from(labelCollection)

         labels.forEach((label) => {
            const tagName = label.parentElement!.tagName

            if (tagName === 'FORM') {
               label.parentElement!.removeChild(label)
            }
         })
      })
   }

   private setSubTitle() {
      const subTitle = document.getElementById('text-code')
      subTitle!.setAttribute('style', 'font-size: 16px')
   }

   private setButtonRedirectLogin() {
      const button = `
         <button name="back-to-login" type="button" data-i18="back-to-login" class="btn submitButton-customizable-outline" 
            onclick="window.location.href = window.location.origin.concat('/login')">
            back to login
         </button>`

      const formCollection = document.getElementsByTagName('form')

      const forms = Array.from(formCollection)

      forms.forEach((form) => {
         form.insertAdjacentHTML('afterend', button)
      })
   }

   public init() {
      const commons = new Commons()

      this.setInputs()
      this.setSubTitle()
      this.setButtonRedirectLogin()

      commons.setReCaptcha()
   }
}
